import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import {
  FloatingDropdown,
  FloatingDropdownOptionProps,
} from 'wix-ui-tpa/cssVars';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { FilterViewModel } from '../../../ViewModel/filterViewModel/filterViewModel';
import { FiltersDataHooks } from './dataHooks.const';

import { classes, st } from './FloatingDropdownFilter.st.css';

export interface FloatingDropdownFilterProps {
  viewModel: FilterViewModel;
}

export const FloatingDropdownFilter: React.FC<FloatingDropdownFilterProps> = ({
  viewModel,
}) => {
  const { t } = useTranslation();
  const { onFilterChanged, onElementClicked } = useCalendarActions();
  const { id, label, options } = viewModel;
  const { isMobile } = useEnvironment();
  const onChange = (selectedOption: FloatingDropdownOptionProps) => {
    onElementClicked(
      WidgetComponents.FILTER,
      WidgetElements.FLOATING_DROPDOWN,
      {
        filterType: id,
      },
    );
    onFilterChanged({ [viewModel.id]: [selectedOption.id] });
  };

  return (
    <FloatingDropdown
      className={st(classes.floatingDropdownLocation, { isMobile })}
      data-hook={FiltersDataHooks.FLOATING_DROPDOWN_FILTER}
      onChange={onChange}
      options={options.map((option) => {
        return { id: option.value, value: option.label, isSelectable: true };
      })}
      label={t('location-picker.mobile.label', { label })}
      value={options.find((option) => option.selected)?.value}
    />
  );
};
